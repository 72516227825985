import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from './pages/Home'
import AboutusPage from './pages/AboutusPage';
import ContactUs from './pages/ContactUs';
import Pharma from './pages/Pharma';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/AboutUs" element={<AboutusPage />} />
        <Route exact path="/ContactUs" element={<ContactUs />} />
        <Route exact path='/Pharma' element={<Pharma />} />
      </Routes>
    </Router> 
  );
}

export default App;
