import React from 'react';
import Demo from '../images/20.webp';
import './AboutUs.css';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

function AboutUs() {
    const navigate = useNavigate()

    const gotToNewPage=()=>{
      navigate("/AboutUs");
    }
    return (
        <div className="aboutus">
            <div className="container2">
                <div className='box1'>
                    <img src={Demo} alt="Veer Chemicals Logo" />
                </div>
                <div className='box2'>
                    <div className='content'>
                        <h4>Your Premier <span className='orange-color'>Destination</span> For <span className='blue-color'>Top-Quality</span> Chemical Products And Expert Trading Services</h4>
                        <p>Welcome to Vagmine Chemicals, your premier destination for top-quality chemical products and expert trading services. At Vagmine Chemicals, we are dedicated to delivering excellence in every aspect of our business, from sourcing the finest chemical compounds to providing unparalleled customer service. . With a commitment to integrity, innovation, and reliability, we have established ourselves as a trusted name in the chemical trading industry.</p>
                        <Button variant="outlined" size='large' style={{marginTop:'10px'}} onClick={() => gotToNewPage()}>Read More</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AboutUs;