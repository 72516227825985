import React from 'react';
import './ValuesSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSearch, faUserFriends, faComments, faThumbsUp } from '@fortawesome/free-solid-svg-icons'; 

const ValuesSection = () => {
  const values = [
    { icon: faCog, title: 'Integrity' },
    { icon: faSearch, title: 'Conscientiousness' },
    { icon: faUserFriends, title: 'Excellence' },
    { icon: faComments, title: 'Responsibility' },
    { icon: faThumbsUp, title: 'Empathy' },
  ];

  return (
    <section className="values-section">
        <div className='value-title'>
          <span className='orange-color'>Our</span> Core <span className='different-color'>Values</span>
          </div>
          <div className="values-container">
        {values.map((value, index) => (
          <div key={index} className="value-card">
            <FontAwesomeIcon icon={value.icon} className="value-icon" />
            <h3>{value.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ValuesSection;
