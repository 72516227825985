import React from 'react';
import './Footer.css'; // Import your CSS file for styling
import VagmineLogoFooter from '../images/logo final vagmine.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

function Footer() {
  return (
    <footer class="footer">
  <div class="footer-content">
    <div class="footer-logo">
      <img src={VagmineLogoFooter} alt="Company Logo" />
    </div>
   
    <div class="footer-section quick-links">
      <h2>Quick Links</h2>
      <ul>
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About Us</a></li>
        <li><a href="#products">Products</a></li>
        <li><a href="#contact">Contact Us</a></li>
      </ul>
    </div>
    <div class="footer-section services">
      <h2>Services</h2>
      <ul>
        <li>pharmaceuticals</li>
        <li>Dyes & pigments</li>
      </ul>
    </div>
    <div class="footer-section contact">
      <h2>Contact</h2>
      <p><LocationOnIcon className='location' /> GIDC, Ankleshwar, Gujarat</p>
      <p><PhoneIcon className='location'/> +91 98258 81463</p>
      <p><MailIcon className='location'/> info@vagminechemicals.com</p>
    </div>
  </div>
  <div class="footer-bottom">
    <button class="quote-button">Request A Quote</button>
    <p>&copy; 2024 Vagmine Chemicals. All rights reserved.</p>
  </div>
</footer>

  );
}

export default Footer;
