import React from 'react';
import Header from './Header'
import MainContent from './MainContent'
import Footer from './Footer'
import Products from './Products'
import AboutUs from './AboutUs'
import './Home.css'
import Values from './ValuesSection'
import BackgroundImage from '../images/22.jpg';

const Home = () => {
  const homeStyle = {
    backgroundImage: `url(${BackgroundImage})`, // Use the imported image
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
  };
  return (
    <div className="Home" style={homeStyle}>
      <Header />
      <MainContent />
      <Products />
      <AboutUs />
      <Values />
      <Footer />
    </div>
  );
}

export {Home};
