import React, { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import medicine from '../images/25.jpeg';
import inks from '../images/8.jpg';
import masterbatch from '../images/8.jpg';
import './MainContent.css'; // Ensure the CSS is imported

function MainContent() {
  const sliderImages = [
    {
      src: medicine,
      title: 'Pharmaceutical Solutions',
      description: 'Delivering top-quality pharmaceutical products to meet your healthcare needs.',
    },
    {
      src: inks,
      title: 'High-Quality Inks',
      description: 'Explore our premium inks for industrial and commercial use.',
    },
    {
      src: masterbatch,
      title: 'Masterbatch Solutions',
      description: 'Specialized masterbatch for plastic manufacturing, ensuring vibrant colors and durability.',
    },
  ];

  const [activeImageNum, setActiveImageNum] = useState(0);
  const length = sliderImages.length;

  const nextSlide = () => {
    setActiveImageNum(activeImageNum === length - 1 ? 0 : activeImageNum + 1);
  };

  const prevSlide = () => {
    setActiveImageNum(activeImageNum === 0 ? length - 1 : activeImageNum - 1);
  };

  if (!Array.isArray(sliderImages) || sliderImages.length <= 0) {
    return null;
  }

  return (
    <div className="landing-pages" style={{ marginTop: '8%' }}>
      <div className='ImageSlide'>
        <div className="image-slider">
          <div className="left">
            <ArrowBackIosIcon onClick={prevSlide} />
          </div>
          <div className="right">
            <ArrowForwardIosIcon onClick={nextSlide} />
          </div>
          {sliderImages.map((currentSlide, ind) => (
            <div
              className={ind === activeImageNum ? "currentSlide active" : "currentSlide"}
              key={ind}
            >
              {ind === activeImageNum && (
                <>
                  <img src={currentSlide.src} className="image" alt={`slide-${ind}`} />
                  <div className="text-overlay">
                    <h2 className="animated-text">{currentSlide.title}</h2>
                    <p className="animated-text">{currentSlide.description}</p>
                  </div>
                </>
              )}
            </div>
          ))}
          <div className="dots">
            {sliderImages.map((_, ind) => (
              <span
                key={ind}
                className={`dot ${ind === activeImageNum ? 'active' : ''}`}
                onClick={() => setActiveImageNum(ind)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainContent;
