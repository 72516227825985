import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Pharma.css';
import contactBg from '../images/18.jpg';

function Pharma() {
  // All products
  const products = [
    { name: 'GABAPENTIN U.S.P 24', cas: '60142-96-3', usage: 'Relieves pain and reduces fever', availability: 'In Stock' },
    { name: 'OXCARBAZEPINE IP', cas: '28721-07-5', usage: 'Pain reliever', availability: 'In Stock' },
    { name: 'MOXIFLOXACIN', cas: '354812-41-2', usage: 'Controls blood sugar in Type 2 diabetes', availability: 'Limited' },
    { name: 'VITAMIN B12', cas: '68-19-9', usage: 'Treats high blood pressure', availability: 'In Stock' },
    { name: 'ETORICOXIB', cas: '202409-33-4', usage: 'Pain relief', availability: 'In Stock' },
    { name: 'SILODOSIN', cas: '160970-54-7', usage: 'Treats enlarged prostate', availability: 'In Stock' },
    { name: 'ATORVASTATIN', cas: '134523-00-5', usage: 'Lowers cholesterol', availability: 'In Stock' },
    { name: 'PREGABALINE', cas: '148553-50-8', usage: 'Relieves nerve pain', availability: 'In Stock' },
    { name: 'PREGABALINE', cas: '148553-50-8', usage: 'Relieves nerve pain', availability: 'In Stock' },
    { name: 'PREGABALINE', cas: '148553-50-8', usage: 'Relieves nerve pain', availability: 'In Stock' },
    { name: 'PREGABALINE', cas: '148553-50-8', usage: 'Relieves nerve pain', availability: 'In Stock' },
    { name: 'PREGABALINE', cas: '148553-50-8', usage: 'Relieves nerve pain', availability: 'In Stock' },
    { name: 'PREGABALINE', cas: '148553-50-8', usage: 'Relieves nerve pain', availability: 'In Stock' },
    { name: 'PREGABALINE', cas: '148553-50-8', usage: 'Relieves nerve pain', availability: 'In Stock' }

    // Add more products here...
  ];

  const itemsPerPage = 3;  // Number of products to show per page
  const [currentPage, setCurrentPage] = useState(0);

  // Pagination logic
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Slice the products array based on the current page
  const currentProducts = products.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <>
      <Header />
      <div className="banner">
        <div className="banner-overlay"></div>
        <img src={contactBg} alt="Background" className="banner-img" />
        <div className="banner-text">
          <h5>Pharmaceuticals</h5>
        </div>
      </div>

      <div className="pharma-section">
        <h1 className="table-title">Pharmaceutical Products</h1>

        <div className="product-grid">
          {currentProducts.map((product, index) => (
            <div className="product-card" key={index}>
              <h3>{product.name}</h3>
              <p><strong>CAS No:</strong> {product.cas}</p>
              <p><strong>Usage:</strong> {product.usage}</p>
              <button className={product.availability === 'In Stock' ? 'in-stock' : 'limited'}>
                {product.availability}
              </button>
            </div>
          ))}
        </div>

        {/* Arrows for Pagination */}
        <div className="pagination">
          <button onClick={handlePrev} disabled={currentPage === 0} className="arrow">
            &#8592; Previous
          </button>
          <span className="page-info">
            Page {currentPage + 1} of {totalPages}
          </span>
          <button onClick={handleNext} disabled={currentPage === totalPages - 1} className="arrow">
            Next &#8594;
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Pharma;
