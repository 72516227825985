import React from 'react';
import Header from './Header'; // Assuming this is the header component
import Footer from './Footer'; // Assuming this is the footer component
import './ContactUs.css'; // Importing the CSS file
import contactBg from '../images/18.jpg'; // Importing the background image
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import BackgroundImage from '../images/22.jpg';

const ContactUs = () => {
  const contactStyle = {
    backgroundImage: `url(${BackgroundImage})`, // Use the imported image
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <div style={contactStyle}>
      <Header />
      
      {/* Banner Section */}
      <div className="contact-banner">
  <div className="overlay"></div> {/* Black opacity overlay */}
  <img src={contactBg} alt="Contact Us" className="contact-banner-img" />
  <div className="contact-banner-text">
    <h5>Contact Us</h5>
  </div>
</div>
      
      {/* Main Contact Section */}
      <div className="contact-main-container">
        <div className="contact-details">
          <h5 className="section-subtitle">Get in touch</h5>
          <h1 className="section-title">Have Any Questions?</h1>
          
          {/* Office Address */}
          <div className="contact-info">
            <div className="icon-wrapper">
              <MapOutlinedIcon className="icon" />
            </div>
            <div>
              <h5>Office Address</h5>
              <p>203, Abhijyot Square, Behind Divya Bhaskar Press, Corporate Road, off S.G Highway, Ahmedabad-380015, Gujarat, India.</p>
            </div>
          </div>

          {/* Email Us */}
          <div className="contact-info">
            <div className="icon-wrapper">
              <EmailOutlined className="icon" />
            </div>
            <div>
              <h5>Email Us</h5>
              <p>abc@info.com</p>
            </div>
          </div>

          {/* Phone Number */}
          <div className="contact-info">
            <div className="icon-wrapper">
              <WifiCalling3OutlinedIcon className="icon" />
            </div>
            <div>
              <h5>Phone Number</h5>
              <p>+91 8756237812</p>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <div className="contact-form-container">
          <form className="contact-form">
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Name" />
              <input type="text" className="form-control" placeholder="Phone" />
            </div>
            <div className="form-group">
              <input type="email" className="form-control" placeholder="Email" />
            </div>
            <div className="form-group">
              <textarea className="form-control" placeholder="Message" rows="4"></textarea>
            </div>
            <button type="submit" className="submit-btn">Submit</button>
          </form>
        </div>
      </div>

      {/* Google Map Section */}
      <div className="map-container">
        <iframe
          title="Google Map"
          src="https://maps.google.com/maps?q=21.596063518445067,73.04066588030803&hl=es;z=16&amp;output=embed"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="google-map"
        ></iframe>
      </div>
      
      <Footer />
    </div>
  );
}

export default ContactUs;
