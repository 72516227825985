import React from 'react';
import "../pages/Products.css";
import inks from '../images/12.png';
import masterbatch from '../images/24.jpeg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Overview() {
  return (
    <div className='products container1'>
      <div className='productsStyle'>
        <h1 className='title'>
          <span className='orange-color'>Explore</span> Our <span className='different-color'>Products</span>
        </h1>
        <div className='row'>
          <div className='column polaroid'>
            <img className="zoom" src={inks} alt="Inks" />
            <div className='hoverAfter'>
              <div className='hoverContent'>
                <div className='arrowHover'>
                  <p>Vagmine Chemicals</p>
                  <ArrowForwardIcon className='arrow' />
                </div>
                <h1>Pharmaceuticals</h1>
              </div>
            </div>
          </div>

          <div className='column polaroid'>
            <img className="zoom" src={masterbatch} alt="Masterbatch" />
            <div className='hoverAfter'>
              <div className='hoverContent'>
                <div className='arrowHover'>
                  <p>Vagmine Chemicals</p>
                  <ArrowForwardIcon className='arrow' />
                </div>
                <h1>Pharmaceuticals</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
