import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './AboutusPage.css';
import aboutusBg from '../images/22.jpg' // Replace with your image
import Lab from '../images/11.jpeg';
import VisionIcon from '../images/v-1.png';
import MissionIcon from '../images/m-1.png';

function AboutusPage() {
  return (
    <>
      <Header />
      <div className="aboutus-hero">
        <div className="hero-text">
          <h1>About Us</h1>
          <p>Discover who we are and our commitment to excellence in the chemical industry.</p>
        </div>
      </div>
      <div className="aboutus-section">
        <div className="about-content">
          <img src={Lab} alt="Lab" className="about-image" />
          <div className="about-text">
            <h2>Welcome to Vagmine Chemicals</h2>
            <p>
            Welcome to Vagmine Chemicals, your premier destination for top-quality chemical products and expert trading services. At Vagmine Chemicals, we are dedicated to delivering excellence in every aspect of our business, from sourcing the finest chemical compounds to providing unparalleled customer service. With a commitment to integrity, innovation, and reliability, we have established ourselves as a trusted name in the chemical trading industry.
            </p>
            <p>
            Founded with a vision to meet the evolving needs of diverse industries, Vagmine Chemicals has swiftly grown into a dynamic force in the market. Our extensive portfolio encompasses a wide range of chemical products, including APIs, Intermediates, dyes, pigments, industrial chemicals, specialty chemicals, solvents, and more. Whether you're in pharmaceuticals, textile, paint, or any other sector, we have the solutions to support your unique requirements.
            </p>
            <p style={{marginBottom:"0px"}}>At Vagmine Chemicals, we believe that chemistry is not merely a science, but an artful blend of creativity and expertise. Join us as “We redefine the possibilities of Chemistry” and collaborate towards a brighter, greener, and more prosperous future. What sets us apart is our unwavering dedication to quality. We understand the critical role that chemicals play in various processes and applications, which is why we go above and beyond to ensure that every product in our inventory meets the highest standards of purity, potency, and performance. </p>
          </div>
        </div>
      </div>
      <div className="cards-section">
        <div className="card">
          <img src={VisionIcon} alt="Vision" className="card-icon" />
          <h3>Our Vision</h3>
          <p>To be a global leader in chemical solutions with a vibrant and innovative approach.</p>
        </div>
        <div className="card">
          <img src={MissionIcon} alt="Mission" className="card-icon" />
          <h3>Our Mission</h3>
          <p>Deliver exceptional service and integral products to the chemical industry through innovative intelligence.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutusPage;
